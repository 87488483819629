import { useEffect, useState } from 'react'

import { QuantityPicker } from 'components/QuantityPicker/QuantityPicker'
import { GlamoProduct } from 'types/gatsby'
import { Checkbox } from 'ui/Checkbox/Checkbox'
import { Flex, Grid, Text } from 'ui/common'
import { formatPrice } from 'utils/formatPrice'

interface ProductSummaryProps
  extends Pick<
    GlamoProduct,
    'glamoId' | 'name' | 'price' | 'priceCleaning' | 'quantity'
  > {
  glamoId: string
  name: string
  price: number
}

export const ProductSummary: React.FC<ProductSummaryProps> = props => {
  const { glamoId, name, price, priceCleaning, quantity: maxQuantity } = props

  const initialQuantity = maxQuantity ? 1 : undefined
  const [quantity, setQuantity] = useState(initialQuantity ?? 0)
  const [includeCleaning, setIncludeCleaning] = useState(true)
  const [priceTotal, setPriceTotal] = useState(
    quantity * price + quantity * (priceCleaning ?? 0) * Number(includeCleaning)
  )

  useEffect(() => {
    setPriceTotal(
      quantity * price +
        quantity * (priceCleaning ?? 0) * Number(includeCleaning)
    )
  }, [quantity, price, priceCleaning, includeCleaning])

  return (
    <Flex as="article" flexDirection="column" sx={{ maxWidth: '800px' }}>
      <Text as="h1">{name}</Text>
      <Text color="gray500" fontSize="xxs">
        Kod produktu: {glamoId}
      </Text>

      <Text color="primary" fontSize="l" fontWeight="bold" mt="s" mb="l">
        {formatPrice(price)}
      </Text>

      {priceCleaning ? (
        <Checkbox
          name="include-cleaning"
          checked={includeCleaning}
          required
          onChange={() => {
            setIncludeCleaning(!includeCleaning)
          }}
        >
          <Text fontSize="s">
            Czyszczenie{' '}
            <Text as="span" fontWeight="bold">
              +{formatPrice(priceCleaning)}
            </Text>
          </Text>
        </Checkbox>
      ) : null}

      <Grid
        gridTemplateColumns="3fr auto"
        gridRowGap="xxxs"
        gridColumnGap="xxl"
        py="l"
      >
        <Text fontSize="s">
          Cena&nbsp;{quantity === 1 ? 'produktu' : 'produktów'} ({quantity}
          &nbsp; szt.)
        </Text>
        <Text fontSize="s" textAlign="end">
          {formatPrice(price * quantity)}
        </Text>
        {priceCleaning ? (
          <>
            <Text fontSize="s">
              Czyszczenie
              {includeCleaning
                ? `\u00A0(${quantity}\u00A0szt.)`
                : ' -\u00A0oddam\u00A0wyczyszczone'}
            </Text>
            <Text fontSize="s" textAlign="end">
              {formatPrice(priceCleaning * quantity * Number(includeCleaning))}
            </Text>
          </>
        ) : null}
        <Text fontSize="s" fontWeight="bold">
          Suma
        </Text>
        <Text fontSize="s" fontWeight="bold" textAlign="end">
          {formatPrice(priceTotal)}
        </Text>
      </Grid>

      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" mr="xl">
          <Text fontSize="s" mr="s">
            Ilość:
          </Text>
          <QuantityPicker
            initialQuantity={initialQuantity}
            maxQuantity={maxQuantity ?? 0}
            onQuantityChange={maxQuantity => setQuantity(maxQuantity)}
          />
        </Flex>
        {maxQuantity ? (
          <Text fontSize="s">
            Dostępność:{' '}
            <Text as="span" fontWeight="bold">
              {maxQuantity}&nbsp;szt.
            </Text>
          </Text>
        ) : (
          <Text fontWeight="bold">Produkt chwilowo niedostępny</Text>
        )}
      </Flex>
      <Text fontSize="xxs" color="gray500" my="m">
        Funkcjonalność Listy życzeń jest obecnie niedostępna. Zapraszamy do
        przesyłania nam listy pożądanych przedmiotów w mailu lub w wiadomości
        kontaktowej.
      </Text>
    </Flex>
  )
}
