import { graphql } from 'gatsby'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Layout from 'components/Layout/Layout'
import { Product } from 'components/Product/Product'
import SEO from 'components/SEO/SEO'
import { defaultCategory } from 'data/categories'
import { GlamoProduct } from 'types/gatsby'

interface GlamoProductPageProps {
  data: {
    glamoProduct: Pick<
      GlamoProduct,
      | 'glamoId'
      | 'name'
      | 'description'
      | 'price'
      | 'priceCleaning'
      | 'quantity'
      | 'mainImageFile'
      | 'mainImageAlt'
      | 'category'
      | 'deliveryTypes'
      | 'specification'
    >
  }
}

const GlamoProductPageTemplate: React.FC<GlamoProductPageProps> = props => {
  const productData = props.data.glamoProduct
  const { glamoId, name, price, category, ...restProductData } = productData

  if (!glamoId || !name || !price) return null

  // todo: remove this after GlamoProduct node creation is updated, so when glamoId is a String, not Date
  // ("createSchemaCustomization" in gatsby's API)
  if (typeof glamoId !== 'string') return null

  return (
    <Layout>
      <SEO title={name || defaultCategory.categoryDisplayName} />
      <Breadcrumbs name={name} category={category || ''} />
      <Product
        glamoId={glamoId}
        name={name}
        price={price}
        {...restProductData}
      />
    </Layout>
  )
}

export default GlamoProductPageTemplate

export const productPageQuery = graphql`
  query ProductsQuery($id: String!) {
    glamoProduct(id: { eq: $id }) {
      glamoId
      name
      description
      price
      priceCleaning
      quantity
      mainImageFile
      mainImageAlt
      category
      deliveryTypes
      specification {
        height
        width
        depth
        length
        opening
        additionalInfo
        shape
        material
        texture
        textileOpacity
        color
      }
    }
  }
`
