import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from 'components/Link/Link'
import { ProductDeliveryType } from 'types/data/model/Product'
import { FileConnection, GlamoProduct } from 'types/gatsby'
import { Box, Grid, Text } from 'ui/common'
import { prepareHtmlText } from 'utils/text/prepareHtmlText'
import { ProductSpecs } from './ProductSpecs'
import { ProductSummary } from './ProductSummary'
import styled from 'styled-components'

const deliveryDescriptionMap: Record<ProductDeliveryType, React.ReactNode> = {
  selfPickup: (
    <Text>
      odbiór osobisty w <Link to="/kontakt">naszej pracowni</Link>
    </Text>
  ),
  courier: <Text>przesyłka kurierska</Text>,
  glamoDelivery: (
    <Text>
      dostarczenie zamówienia przez pracownika Glamo.pl pod wskazany adres (na
      terenie Warszawy i okolic)
    </Text>
  ),
}

interface ProductProps
  extends Pick<
    GlamoProduct,
    | 'glamoId'
    | 'name'
    | 'description'
    | 'price'
    | 'priceCleaning'
    | 'quantity'
    | 'mainImageFile'
    | 'mainImageAlt'
    | 'deliveryTypes'
    | 'specification'
  > {
  glamoId: string
  name: string
  price: number
}

export const Product: React.FC<ProductProps> = props => {
  const {
    glamoId,
    name,
    description,
    mainImageFile,
    mainImageAlt,
    deliveryTypes,
    specification,
  } = props

  const productsImages =
    useStaticQuery<Record<string, Pick<FileConnection, 'edges'>>>(imagesQuery)

  const imageEdge =
    mainImageFile &&
    productsImages.allProductsImages.edges.find(fileEdge => {
      return fileEdge.node.name === mainImageFile
    })
  // check if all products have corresponding image file available
  if (process.env.NODE_ENV === 'development' && mainImageFile && !imageEdge) {
    // eslint-disable-next-line no-console
    console.log(
      `[INFO] Oops: Missing '${mainImageFile}.jpg', product: '${name}' (id: ${glamoId})`
    )
    throw new Error(
      `Please add a correct query for a file: '${mainImageFile}', product: '${name}' (id: ${glamoId}).`
    )
  }

  const gatsbyImageData =
    imageEdge && imageEdge.node.childImageSharp?.gatsbyImageData

  return (
    <Grid
      justifyContent="center"
      gridTemplateColumns={{
        _: 'minmax(1fr, 480px)',
        mobileL: '450px',
        tablet: '13fr 11fr',
        monitorS: '480px 1fr',
        monitor: '480px 480px',
      }}
      gridGap={{ _: 'l', monitorS: 'xxxl' }}
      padding="l"
      maxWidth={{
        monitorS: '992px',
        monitor: 'calc(2 * 480px + 64px + 2 * 24px)',
      }}
      marginX="auto"
    >
      {/* Product Preview */}
      {gatsbyImageData && (
        <Box width="100%" height="100%" maxHeight="480px" maxWidth="480px">
          <GatsbyImage
            image={gatsbyImageData}
            alt={`Zdjęcie przedstawiające: ${mainImageAlt || name}`}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
          />
        </Box>
      )}

      {/* Product Summary */}
      <ProductSummary {...props} />

      {/* Product Specs and Delivery */}
      <SpecsAndDeliveryWrapper>
        {specification && <ProductSpecs specification={specification} />}
        <DetailsTitle>Dostawa</DetailsTitle>
        <Text>Dostępne sposoby dostawy:</Text>
        <ul>
          {deliveryTypes?.map(
            type =>
              type &&
              type in deliveryDescriptionMap && (
                <li key={type}>
                  {deliveryDescriptionMap[type as ProductDeliveryType]}
                </li>
              )
          )}
        </ul>
      </SpecsAndDeliveryWrapper>

      {/* Product Description */}
      <Box>
        <DetailsTitle>Opis Produktu</DetailsTitle>
        {prepareHtmlText(description || name)
          .split('\n')
          .map((text, index) => (
            <Text
              key={index}
              mb="xxxs"
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ))}
      </Box>
    </Grid>
  )
}

const SpecsAndDeliveryWrapper = styled(Box)`
  @media ${({ theme }) => theme.devices.tablet.max} {
    order: 1;
  }
`

export const DetailsTitle: React.FC = ({ children, ...restProps }) => (
  <React.Fragment {...restProps}>
    <Text as="h2" fontSize="l">
      {children}
    </Text>
    <Box
      height="1px"
      backgroundColor="gray500"
      maxWidth="240px"
      marginTop="xxxs"
      marginBottom="xxs"
    />
  </React.Fragment>
)

const imagesQuery = graphql`
  query ProductsImagesQuery {
    allProductsImages: allFile(
      filter: { relativeDirectory: { eq: "products" } }
    ) {
      edges {
        node {
          ...productImageThumbnail
          name
        }
      }
    }
  }
`
