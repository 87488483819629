import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Box, Button, Flex, Text } from 'ui/common'

interface QuantityPickerProps {
  initialQuantity?: number
  maxQuantity?: number
  onQuantityChange?: (quantity: number) => void
}

export const QuantityPicker: React.FC<QuantityPickerProps> = ({
  initialQuantity,
  maxQuantity = 0,
  onQuantityChange,
  ...rest
}) => {
  const quantityInputRef = useRef<HTMLInputElement>(null)
  const [quantity, setQuantity] = useState(
    initialQuantity !== undefined
      ? Math.max(Math.min(initialQuantity, maxQuantity), 0)
      : 0
  )
  const disabled = maxQuantity === 0

  useEffect(() => {
    if (onQuantityChange) onQuantityChange(quantity)
  }, [quantity, onQuantityChange])

  const handleIncrement = useCallback(() => {
    if (disabled) return

    setQuantity(quantity => Math.max(Math.min(quantity + 1, maxQuantity), 0))
  }, [disabled, maxQuantity])

  const handleDecrement = useCallback(() => {
    if (disabled) return

    setQuantity(quantity => Math.max(Math.min(quantity - 1, maxQuantity), 0))
  }, [disabled, maxQuantity])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = Number(e.target.value.replace(/,/g, '.'))
      if (Number.isNaN(value)) value = 0

      const newQuantity = Math.max(Math.min(Math.round(value), maxQuantity), 0)
      setQuantity(newQuantity)

      if (newQuantity === 0) {
        quantityInputRef.current?.focus()
        quantityInputRef.current?.select()
      }
    },
    [maxQuantity]
  )

  const onFocus = useCallback(() => {
    if (quantity === 0) {
      quantityInputRef.current?.focus()
      quantityInputRef.current?.select()
    }
  }, [quantity])

  return (
    <Flex {...rest}>
      <ChangeQuantityButton
        variant="outline"
        variantBgColor="gray200"
        onClick={handleDecrement}
        aria-label="Minus"
      >
        <Text fontSize="l" lineHeight="none">
          −
        </Text>
      </ChangeQuantityButton>
      <Box maxWidth="48px" flexGrow={1}>
        <PickerInput
          ref={quantityInputRef}
          type="number"
          name="quantity"
          id="quantity"
          min={0}
          max={maxQuantity}
          value={quantity}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
        />
      </Box>
      <ChangeQuantityButton
        variant="outline"
        variantBgColor="gray200"
        onClick={handleIncrement}
        aria-label="Plus"
      >
        <Text fontSize="l" lineHeight="none">
          ＋
        </Text>
      </ChangeQuantityButton>
    </Flex>
  )
}

const ChangeQuantityButton = styled(Button)`
  border: none;
  padding: ${p => p.theme.space.xxxs}px ${p => p.theme.space.xxs}px;
`

const PickerInput = styled.input`
  height: 100%;
  width: 100%;
  padding: ${p => p.theme.space.xxxs}px ${p => p.theme.space.xxs}px;
  background-color: ${p => p.theme.colors.gray200};
  border: none;
  border-left: 2px solid ${p => p.theme.colors.gray400};
  border-right: 2px solid ${p => p.theme.colors.gray400};
  text-align: center;

  font-size: ${p => p.theme.fontSizes.xs};

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`
