import {
  ProductSpecificationHeader,
  ProductSpecificationHeaderNumber,
} from 'types/data/model/Product'
import { Box, Flex, Grid, Text } from 'ui/common'
import { capitalizeFirstLetter } from 'utils/text/capitalizeFirstLetter'
import { prepareHtmlText } from 'utils/text/prepareHtmlText'
import { DetailsTitle } from './Product'

export type ProductAttributeUnit = 'centimeter' | 'meter' | 'piece'

const specsPropertiesUnitMap: Record<
  ProductSpecificationHeaderNumber,
  ProductAttributeUnit
> = {
  height: 'centimeter',
  width: 'centimeter',
  depth: 'centimeter',
  length: 'centimeter',
  opening: 'centimeter',
  // lightsCable: 'meter',
  // lightsQuantity: 'piece',
}

const unitDisplayMap: Record<ProductAttributeUnit, string> = {
  centimeter: 'cm',
  meter: 'mb',
  piece: 'szt.',
}

function getUnitDisplay(property: string) {
  return property in specsPropertiesUnitMap
    ? unitDisplayMap[
        specsPropertiesUnitMap[property as ProductSpecificationHeaderNumber]
      ]
    : ''
}

const attributeDisplayMap: Record<ProductSpecificationHeader, string> = {
  additionalInfo: 'Dodatkowe informacje',
  color: 'Kolor',
  crossSection: 'Przekrój',
  depth: 'Głębokość',
  height: 'Wysokość',
  length: 'Długość',
  material: 'Materiał',
  opening: 'Otwór',
  shape: 'Kształt',
  textileOpacity: 'Przezroczystość materiału',
  texture: 'Tekstura',
  width: 'Szerokość',
}

function getAttributeDisplay(property: string) {
  return property in attributeDisplayMap
    ? attributeDisplayMap[property as ProductSpecificationHeader]
    : property
}

interface ProductSpecsProps {
  specification: Partial<
    Record<ProductSpecificationHeader, string | number | null>
  >
}

export const ProductSpecs: React.FC<ProductSpecsProps> = ({
  specification,
}) => {
  // check if specification related fields (measurements, material etc) have
  // non falsy values
  const isSpecificationAvailable =
    !!specification && Object.values(specification).filter(Boolean).length > 0

  if (!isSpecificationAvailable) return null

  return (
    <Box marginBottom="l">
      <DetailsTitle>Właściwości</DetailsTitle>
      <Text pb="xxs">Szczegóły materiałowe i wymiarowe produktu:</Text>
      <Grid gridTemplateColumns="1fr">
        {Object.entries(specification)
          .filter(([property, value]) => Boolean(value))
          .map(([property, value], index) => {
            if (!value || (typeof value === 'string' && !value.trim()))
              return null

            const htmlValue =
              typeof value === 'string'
                ? capitalizeFirstLetter(prepareHtmlText(value))
                : `${value}\u00A0${getUnitDisplay(property)}`

            return (
              <Flex
                key={property}
                backgroundColor={index % 2 === 1 ? 'gray200' : undefined}
                px="xxs"
                py="xxxs"
              >
                <Text fontWeight="bold" mr="l">
                  {getAttributeDisplay(property)}
                </Text>
                <Box>
                  {htmlValue.split('\n').map((text, index) => (
                    <Text
                      key={index}
                      // @ts-ignore
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  ))}
                </Box>
              </Flex>
            )
          })}
      </Grid>
    </Box>
  )
}
