import Link from 'components/Link/Link'
import { defaultCategory } from 'data/categories'
import { Flex, Text, TextButton } from 'ui/common'
import { getCategoryFromCategoryId } from 'utils/categories'

interface BreadcrumbsProps {
  name: string
  category?: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ category, name }) => {
  const productCategory = getCategoryFromCategoryId(category || '')

  return (
    <Flex padding="l" alignItems="center" flexWrap="wrap">
      <BreadcrumbsItem text="Strona główna" to="/" />
      <BreadcrumbsItem
        text="Wypożyczalnia"
        to={`/dekoracje/${defaultCategory.slug}`}
      />
      <BreadcrumbsItem
        text={productCategory.categoryDisplayName}
        to={`/dekoracje/${productCategory.slug}`}
      />
      <Text color="gray500">{name}</Text>
    </Flex>
  )
}

export default Breadcrumbs

const BreadcrumbsItem = ({ to, text }: { to: string; text: string }) => (
  <>
    <Link to={to}>
      <TextButton
        underline="hover"
        variantColor="primary400"
        fontWeight="regular"
      >
        {text}
      </TextButton>
    </Link>
    <Text minWidth="20px" textAlign="center">
      {' > '}
    </Text>
  </>
)
